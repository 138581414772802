.ContainerHome {
  background-image: url("../../../imgs/PBanner5.png");
  /* Reemplaza 'ruta-de-tu-imagen.jpg' con la ruta correcta de tu imagen */
  background-size: cover;
  background-position: center;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 40px;
  text-align: center;
  flex-direction: column;
  font-family: "Mulish", sans-serif;
  height: 100vh;
  margin: 0 !important;
}

.darkbox {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.362);
  width: 100%;
  height: 100vh;
}

.title {
  font-weight: bold;
  font-size: 65px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  color: #ffffff;
  z-index: 10;
}

.description {
  font-size: 35px;
  /* font-family: "Lato", sans-serif; */
  margin-top: 3rem;
  z-index: 10;
  color : #ffffff;
}

.divSearch {
  margin-top: 3rem;
  display: flex;
  width: 60%;
  height: 6rem;
  border-radius: 30px;
  justify-content: center;
  border: none;
  background-color: #dedede4a;
  z-index: 10;
}

.divLabel {
  display: flex;
  align-items: center;
  width: auto;
}

.IconSearch {
  position: relative;
  left: 6%;
  color: #EC920B;
}

.inputSearch {
  width: 40rem;
  margin-right: 4rem;
  height: 3rem;
  border: none;
  border-radius: 30px;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  padding-left: 7%;
  outline: none;
}

.inputSearch:focus {
  border: 2px solid #ec920b;
}

.inputSearch::placeholder {
  padding-left: 1rem;
  font-weight: bold;
  font-size: 20px;
  font-family: "Lato", sans-serif;
}



.divbutton {
  display: flex;
  align-items: center;
  height: 100%;
  width: 20%;
}

.button {
  height: 3rem;
  width: 100%;
  border: none;
  border-radius: 30px;
  background-color: #ec920b;
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(32, 32, 32, 0.6);
}


@media only screen and (max-width: 768px) {

  .ContainerHome {
    overflow-x: hidden;
  }


  .title {
    width: 80%;
    font-size: 45px;
  }

  .description {
    font-size: 25px;
    width: 80%;
  }

  .divSearch {
    width: 80%;
    max-width: 30rem;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .divLabel {
    position: relative;
    width: 100%;
    padding-inline: .3rem;
  }

  .divLabel svg {
    position: absolute;
    left: 6%;
    top: 45%;
  }

  .inputSearch {
    margin-top: 1rem;
    margin-inline: auto;
    width: 95%;
  }

  .divbutton {
    width: 90%;
    margin-block: 1rem;
  }

  .button {
    font-size: 1rem;
    width: 100%;
  }
}


@media only screen and (max-width: 375px) {

  .inputSearch::placeholder {
    font-size: medium;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .title {
    width: 80%;
    font-size: 55px;
  }

  .description {
    font-size: 25px;
    width: 80%;
  }

  .divSearch {
    width: 90%;
    align-items: center;
  }


  .divLabel {
    position: relative;
    width: 80%;
  }

  .divLabel svg {
    position: absolute;
    left: 12%;
    top: 30%;
  }

  .inputSearch {
    margin-inline: auto;
    width: 85%;
  }

}

@media only screen and (min-width: 1024px) and (max-width: 1700px) {

 

  .divSearch {
    width: 90%;
    align-items: center;
  }


}