.items_render_dynamic_ReservationCard {
    display: flex;
    flex-direction: row;
    width: 60rem;
    justify-content: space-evenly;
    font-size: large;
    align-items: center;

}

.title_reservation_card {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
}


.items_destination_ReservationCard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
}

.items_destination_ReservationCard>p {
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.items_render_dynamic2_ReservationCard {
    display: flex;
    flex-direction: row;
    width: 35rem;
    justify-content: space-between;
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
}

.items_turims_ReservationCard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
}


.items_turims_ReservationCard>p {
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.item_day_ReservationCard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
    width: 9rem;
    justify-content: center;
    align-items: center;
}

.item_day_ReservationCard p {
    font-weight: bold;
    padding: 0;
    margin: 0;
}




@media screen and (min-width: 321px) and (max-width: 480px) {

    .items_render_dynamic2_ReservationCard {
        display: flex;
        flex-direction: row;
        width: 90%;
        justify-content: space-between;
        margin: 0;
        padding: 0;
    }

    .item_day_ReservationCard{
        width: 6rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .items_turims_ReservationCard{
        font-size: 0.6rem;
        width: 50%;
    }

   
}

@media only screen and (max-width: 425px) {

    .items_render_dynamic_ReservationCard{
        font-size: 0.3rem;
        width: 100%;
    }

    .title_reservation_card{
        font-size: 0.9rem;
    }

    .items_render_dynamic2_ReservationCard {
        display: flex;
        flex-direction: column;
        width: 13rem;
        justify-content: space-between;
        font-size: 0.8rem;
        margin: 0;
        padding: 0;
    }

 

    .items_turims_ReservationCard{
        /* background-color: aqua; */
        font-size: 1rem;
        
    }
    .item_day_ReservationCard{
        padding-right: 0.9rem;
        /* background-color: brown; */
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        justify-content: space-evenly;
        padding: 0;
    }


}

@media screen and (min-width: 426px) and (max-width: 768px) {

    .items_render_dynamic_ReservationCard{
        font-size: 1rem;
        width: 100%;
    }

    .title_reservation_card{
        font-size: 1.5rem;
    }

    .items_render_dynamic2_ReservationCard {
        display: flex;
        flex-direction: row;
        width: 35rem;
        justify-content: space-between;
        font-size: 1.3rem;
        margin: 0;
        padding: 0;
    }

    .items_turims_ReservationCard{
        font-size: 1.3rem;
    }

    .item_day_ReservationCard{
        padding-right: 0.9rem;
        display: flex;
        flex-direction: row;
        font-size: 1.3rem;
        justify-content: space-evenly;
        padding: 0;
    }

  

}

@media screen and (min-width: 769px) and (max-width: 1024px) {

    .items_render_dynamic_ReservationCard{
        font-size: 1.3rem;
        width: 100%;
    }

    .title_reservation_card{
        font-size: 1.5rem;
    }

    .items_render_dynamic2_ReservationCard {
        display: flex;
        flex-direction: row;
        width: 35rem;
        justify-content: space-between;
        font-size: 1.3rem;
        margin: 0;
        padding: 0;
    }

    .items_turims_ReservationCard{
        font-size: 1.3rem;
    }

    .item_day_ReservationCard{
        padding-right: 0.9rem;
        display: flex;
        flex-direction: row;
        font-size: 1.3rem;
        justify-content: space-evenly;
        padding: 0;
    }

}

