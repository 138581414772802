.container_footer {
  width: 100%;
  height: 12rem;
  background-color: #2f2f2f;
  display: flex;
  justify-content: center;
  z-index: 99;
  /* margin-top: 5rem; */
}

.items_footer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 80%;
  background-color: #2f2f2f;
  justify-content: space-around;
  align-items: center;
}

.rectang {
  height: 10rem;
  width: 15rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #9c0c0c; */
}

.rectang a {
  margin: 0.15rem 0;
  text-decoration: none;
  color: white;
  width: 100%;
  text-align: left;
}

.rectang a:hover {
  color: #ec920b !important;
}

.rectang_img {
  width: 20%;
  height: 60%;
  background-color: #2f2f2f;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img_footer {
  height: 15rem;
}

.copyright_foopter {
  width: 100%;
  background-color: black;
  text-align: center;
  font-size: 1rem;
  color: white;
}

.group-itemFooter{
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.group-itemFooter svg {

  padding: 0.5rem;
  margin-right: 2rem;
  border-radius: 100%;
  background-color: #ec920b;
}

.cw>svg {
  color: white !important;
}



@media only screen and (max-width: 832px) {

  .container_footer{
    margin-top: 1rem;
  }

  .items_footer{
    width: 100%;
  }
  
  
  .rectang{
    width: 5rem;
    font-size: 0.6rem;
    text-align: start;
    font-size: 0.6rem;
}

.rectang_img{
  background-color: transparent;
  display: flex;
  /* justify-content: start; */
  margin-top: 3rem;
}

.img_footer {
  height: 4rem;
  width: 5rem;
}

.group-itemFooter{
  margin-bottom: 0.6rem;
  padding: 0;
}

.group-itemFooter svg{
  margin-right: 0;
  padding: 0.3rem;
}

}

@media only screen and (max-width: 1024px) {

  .img_footer {
    height: 4rem;
    width: 4rem;
 
  }
}