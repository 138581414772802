.container-webView{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: #F0F0F0;
}

.webView-logo{
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    color : #FFFFFF;
    justify-content: left;
    padding-left: 15rem;
}


.item-one-webView{
    background-color: #EC920B;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 24rem;
    color: #FFFFFF;
}

.item-one-webView > h1{
    font-size: 3rem;
    font-weight: 700;

}

.title_webView-users_facturation{
    font-size: 1.5rem;
    font-weight: 700;

}

.text_webView-users_facturation{
    font-size: 1.1rem;
    font-weight: 400;

}
.buttons-webVier-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 70rem;
    height: 5rem;
}

.buttons-webVier-container > button{
    width: 25rem;
    height: 3.5rem;
    background-color: #000000;
    color: #FFFFFF;
    border: none;
    border-radius: 1.5rem;
    font-size: 1.2rem;
    font-weight: 700;
    box-shadow: 0px 0.5px 10px 0px #000000;

}

.buttons-webVier-container > button:hover {
    opacity: 0.8;
    background-color: black;

   
  }

.item-two-webView-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 36rem;
    background-color: #F0F0F0;
}

.item-tree-webView-containe{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 30rem;
    background-color: #F0F0F0;
    font-family: 'Roboto', sans-serif;
}

.titles-webView > h3{
    font-size: 2rem;
    font-weight: 700;
    color: #000000;
}

.items-user-information > h3{
    font-size: 1.9rem;
    /* font-weight: 700; */
    color: #000000;
}

.information-webView-users{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: #0bec1e; */
    width: 100%;
    text-align: center;
}

@media screen and (min-width: 1024px){
    .buttons-webVier-container{
        width: 80%;
    }
    
}


@media screen and (min-width: 769px) and (max-width: 1023px) {
    .buttons-webVier-container{
        width: 100%;
    }
}

@media screen and (min-width: 426px) and (max-width: 768px) {

    .item-one-webView > h1{
        font-size: 2rem;
    }
    .item-one-webView > h2{
        font-size: 1.5rem;
    }

    .buttons-webVier-container{
        width: 100%;
    }
    .buttons-webVier-container> button{
        width: 14rem;
        height: 3rem;
        font-size: 0.8rem;
    }
}


@media screen and (min-width: 375px) and (max-width:  425px) {
    .item-one-webView > h1{
        font-size: 1.5rem;
    }
    .item-one-webView > h2{
        font-size: 1.2rem;
    }

    
    .buttons-webVier-container{
        width: 100%;
    }
    .buttons-webVier-container> button{
        width: 10rem;
        font-size: 0.8rem;
    }

    .information-webView-users{
        
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}


@media screen and (min-width: 320px) and (max-width: 374px) {
    .item-one-webView > h1{
        font-size: 1.5rem;
    }
    .item-one-webView > h2{
        font-size: 0.9rem;
    }

    
    .buttons-webVier-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 10rem;
    }
    .buttons-webVier-container> button{
        width: 10rem;
        font-size: 0.8rem;
    }

    .webView-logo{
        padding-left: 2rem;
    }

    .item-tree-webView-containe{
        width: 15rem;
    }
    
    .information-webView-users{
        
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .titles-webView > h3{
        font-size: 0.7rem;
    }
}


