.container_carroselCustom {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    justify-items: center;
    align-items: center;
    /* background-color: rgb(63, 192, 51); */
  }

  @media (max-width: 780px) {
    .container_carroselCustom {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
  

  /* @media (max-width: 320px) {
    .container_carroselCustom{
      width: 100%;
      
    }
  } */