.containerCharacteristics {
    background-color: #F0F0F0;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;

}

.item-one-Characteristics {
    background-color: #F0F0F0;
    height: 10rem;
    text-align: center;
    padding-top: 3rem;
}

.item-two-Characteristics {

    background-color: #F0F0F0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.items_container {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

@media only screen and (max-width: 375px) {
    .suscriptors_item_one > div .input_email_suscriptors::placeholder{
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 425px) {

    .containerCharacteristics {
        margin-top: 6rem;
    }

    .item-one-Characteristics {
        width: 90%;
        height: auto;
        margin-inline: auto;
    }

    .item-two-Characteristics {
        margin-top: 1%;
        width: 100%;
        height: auto;
    }

    .items_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .container-CardCharacteristics {
        width: 70%;
        justify-content: center;
    }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
    .containerCharacteristics {
        margin-top: 16rem;
    }

    .item-one-Characteristics {
        width: 80%;
        height: auto;
        margin-inline: auto;
    }

    .item-two-Characteristics {
        height: auto;
    }

    .items_container {
        margin-top: 2rem;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .container-CardCharacteristics {
        width: 70%;
        max-width: 20rem;
        margin-inline: 1rem;
        justify-content: center;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .item-one-Characteristics {
        width: 80%;
        height: auto;
        margin-inline: auto;
    }

    .item-two-Characteristics {
        height: auto;
    }

    .items_container {
        margin-top: 2rem;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .container-CardCharacteristics {
        width: 70%;
        max-width: 20rem;
        margin-inline: 1rem;
        justify-content: center;
    }
}

@media only screen and (min-width: 1025px) {
    .items_container {
        width: 100%;
        max-width: 100rem;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .container-CardCharacteristics {
        width: 70%;
        max-width: 20rem;
        margin-inline: 1rem;
        justify-content: center;
    }
}