.container_tourimsCalendar{
    display: flex;
    flex-direction: column;
}

.container_tourimsCalendarButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}

.container_tourimsCalendarButtons > button{
    margin-top: 1rem;
    height: 2rem;
    width: 6rem;
    border-radius: 1.5rem;
    border: none;
}