.container-CardCharacteristics{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 21%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 5px 5px 8px 0px rgba(0,0,0,0.75);
    padding-top: 1.3rem;
    height: 18rem;
}

.border1{
    border-bottom: 6px solid #ff5249;
}
.border2{
    border-bottom: 6px solid #ff7a43;
}
.border3{
    border-bottom: 6px solid #fda838;
}
.border4{
    border-bottom: 6px solid #ffe470;
}


.texts h1{
    margin: .5rem 0;
    font-size: 1.5rem;
    text-align: center;
    color: #000000;
    margin-bottom: .2rem;
    font-weight: 500;
}

.texts p{
    margin-top: .5rem;
    font-size: .9rem;
    text-align: center;
    color: #546075;
    margin-bottom: 2rem;
    text-wrap: balance;
    
}
