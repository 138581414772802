.container_Reservation {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.container_Reservation>h1 {
    color: black;
    text-align: left;
    background-color: transparent;
    width: 60rem;
    text-align: center;
}

.item_one_Reservation {
    height: 35rem;
    width: 60rem;
    border-radius: 1.5rem;
    display: flex;
}

.item_one_Reservation>img {
    width: 100%;
    border-radius: 1.5rem;
}

.item_description_Reservation {
    background-color: #EC920B;
    height: 8rem;
    width: 42rem;
    border-radius: 1.5rem;
    display: flex;
    opacity: 0.8;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    position: absolute;
    margin-top: 25rem;
    margin-left: 10rem;
}



@media screen and (max-width: 425px){

    .item_one_Reservation {
        height: 35rem;
        width: 100%;
        border-radius: 1.5rem;
        display: flex;
    }

    .item_one_Reservation>img {
        width: 100%;
        border-radius: 1.5rem;
    }

    .item_description_Reservation {
        background-color: #EC920B;
        height: 8rem;
        width: 100%;
        border-radius: 1.5rem;
        display: flex;
        opacity: 0.8;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        position: absolute;
        margin-top: 25rem;
        margin-left: 0rem;
    }

    .container_Reservation >h1 {
        color: black;
        text-align: left;
        background-color: transparent;
        width: 100%;
        text-align: center;
    }
}

@media screen and (min-width: 426px) and ( max-width: 768px) {

    .item_one_Reservation {
        height: 35rem;
        width: 100%;
        border-radius: 1.5rem;
        display: flex;
    }

    .item_one_Reservation>img {
        width: 100%;
        border-radius: 1.5rem;
    }

    .items_render_dynamic2_ReservationCard{
        width: 100%;
    }

    

    .item_description_Reservation {
        background-color: #EC920B;
        height: 8rem;
        width: 100%;
        border-radius: 1.5rem;
        display: flex;
        opacity: 0.8;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        position: absolute;
        margin-top: 25rem;
        margin-left: 0rem;
    }

    .container_Reservation >h1 {
        color: black;
        text-align: left;
        background-color: transparent;
        width: 100%;
        text-align: center;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

    .item_one_Reservation {
        height: 35rem;
        width: 100%;
        border-radius: 1.5rem;
        display: flex;
    }

    .item_one_Reservation>img {
        width: 100%;
        border-radius: 1.5rem;
    }

    .items_render_dynamic2_ReservationCard{
        width: 100%;
    }

    .item_description_Reservation {
        background-color: #EC920B;
        height: 8rem;
        width: 100%;
        border-radius: 1.5rem;
        display: flex;
        opacity: 0.8;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        position: absolute;
        margin-top: 25rem;
        margin-left: 0rem;
    }

    .container_Reservation >h1 {
        color: black;
        text-align: left;
        background-color: transparent;
        width: 100%;
        text-align: center;
    }
}


    





