/* .container_modalReservation{
    background-color: #505050;
    height: 90rem;
    width: 30rem;
} */

.modal_reservarion{
    background-color: #2f2f2fc5;
}

.modal.show .modal-dialog {
    height: 42rem;
    transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.modal-header {
    justify-content: center;
    background-color: #000000;
    color: white;
    border-bottom: none;
}

.modal-body {
 background-color: #505050;
 color: white;
 padding: 0;
 margin: 0;
}

.title_modalReservation{
    color: white;
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
}

.items_modalReservation{
    color: white;
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
}

.container_items_modalReservation{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
}

.container_items_modalReservation_two{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
}


.container_items_modalReservation_two .items_modalReservation > h5 {
    color: white;
    text-align: justify;
}


.modal-footer {
    justify-content: space-evenly;
    background-color: #000000;
    border-top: none;
}


.modal-footer .btn_modalReservation_primary {
    margin: 0;
    color: #fff;
    background-color: #EC920B;
    border: none;
    border-radius: 1.5rem;
    width: 8rem;
    height: 3rem;
}

.modal-footer .btn_modalReservation_primary:hover {
    color: #fff;
    background-color: #46ad32;
    border: none;
}

.modal-footer .btn_modalReservation_secondary {
    margin: 0;
    color: #fff;
    background-color: #2F2F2F;
    border-radius: 1.5rem;
    width: 8rem;
    border: none;
    height: 3rem;
}

.modal-footer .btn_modalReservation_secondary:hover {
    color: #fff;
    background-color: #fa0303;
    
}