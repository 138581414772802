.container_PoliciesAndConditions {
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.space_policies {
    height: 10rem;
    display: flex;
    width: 100%;
}

.containe_texts_PoliciesAndConditions {
    height: auto;
    width: 68rem;
}

.containe_texts_PoliciesAndConditions h5 {
    font-size: 1.3rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 2rem;
    text-align: center;
    padding-top: 1rem;
}

.containe_texts_PoliciesAndConditions p {
    color: gray;
    text-align: justify;
}

.containe_texts_PoliciesAndConditions b {
    color: #000000
}