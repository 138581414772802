.container_login {
    height: 100vh;
    width: 100%;
    background-color: #9B9B9B;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: #2F2F2F 0px 0px 30px 90px;

}

.login_item_row {
    display: flex;
    align-items: center;
    height: 65%;
    width: 50%;
    background-color: azure;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
}

.login_item_one {
    background-color: #2F2F2F;
    color: #EC920B;
    border-radius: 0px 0px 0px 10px;
}


.login_item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
}

.login_item_input {

   border-radius:  20px;
    height: 45px;
    text-indent: 20px;
    width: 15rem;

}

.login_item_input:focus {

    border-color: #EC920B;
    outline: none;

}

.login_item_one_button {
    border-radius: 20px;
    background-color: #EC920B;
    height: 50px;
    color: white;
    width: 110px;
    border: none;
}

.title_login_item_two {
    font-size: 1rem;
    font-weight: bold;
}

.login_item_two_button {
    color: orange;
    border-radius: 20px;
    background-color: white;
    height: 50px;
    width: 100px;
    border: none;
    position: relative;
    top: 5.3rem;
}

.login_item_three {

    background-color: #2F2F2F;
    color: #EC920B;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

}

.login_item_one,
.login_item_three {
    width: 50%;
    height: 100%;
}

.deslizado .login_item_two {
    transform: translateX(-350%);
    border-radius: 10px 0px 0px 10px;
}

.login_item_two {
    background-color: #EC920B;
    width: 20%;
    height: 65%;
    border-radius: 0px 10px 10px 0px;
    color: white;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    transform: translateX(-200%);
    position: absolute;
    left: 95%;
    transition: transform 0.5s ease, width 0.5s ease;
}

.boton_back_Login {
    background-color: #EC920B;
    color: white;
    border: none;
    border-radius:0 50% 50% 0;
    height: 50px;
    width: 50px;
    font-weight: bold;
    font-size: 3rem;
    cursor: pointer;
    position: absolute;
    top: 28%;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);

}

.login_item_three > div > div ion-icon {
    position: relative;
    left: -2rem;
    top: 0.2rem;
    font-size: 1.2rem;
}

.login_item_one > div > div ion-icon {
    position: relative;
    left: -2rem;
    top: 0.2rem;
    font-size: 1.2rem;
}


  

@media (min-width: 1024px) {
    .login_item_row {
        width: 61%;
    }


    .login_item_input {
        
        text-indent:  6px;
    }

    .login_item_two {
        width: 30%;
        height: 65%;
        transform: translateX(-148%);
    }

    .deslizado .login_item_two {
        transform: translateX(-252%);
    }
    
    .login_item{
        width: 96%;
    }

    .login_item_two_button>button:hover {
        
        background-color: white;
        opacity: 0.5;
        color: black;
       
       
    }

    .login_item_two_button>button:focus {
        color: black;
        opacity: 9;
    }
    .login_item_two_button {
        width: 100px;
        
    }

    .login_item_three > div > div ion-icon {
        position: relative;
        right: 1rem;
        top: 0.1rem;
        font-size: 1.2rem;
    }
    
    .login_item_one > div > div ion-icon {
        position: relative;
        /* left: 0; */
        /* right: -3rem; */
        /* top: 0; */
        font-size: 1.2rem;
    }
    

}

@media (min-width: 768px) and (max-width: 1023px) {
  
    .login_item_two {
        width: 25%;
        height: 65%;
        transform: translateX(-176%);
    }

    .deslizado .login_item_two {
        transform: translateX(-280%);
    }
    
    .login_item{
        width: 96%;
    }

    .login_item_input {
        width: 80%;
        font-size: 0.7rem;
        text-indent:  6px;

    }

    .login_item_three>div>div {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .login_item_one>div>div {

        width: 100%;
        justify-content: center;
        display: flex;

    }

    .login_item_title>h1 {
        font-size: 1.3rem;

    }

    .login_item_two_button {
        width: 100px;
    }


    .login_item_two_button>button{
        background-color: white;
        border: none;
       
    
    }

    .login_item_three > div > div ion-icon {
        position: relative;
        left: -1.7rem;
        top: 0.7rem;
        font-size: 1.2rem;
    }
    
    .login_item_one > div > div ion-icon {
        position: relative;
        left: -1.7rem;
        top: 0.7rem;
        font-size: 1.2rem;
    }

}

@media (min-width: 425px) and (max-width: 767px) {
    .login_item_row {
        width: 96%;
    }

    .login_item_one {
        width: 50%;

    }

    .login_item_one>div>div {

        width: 100%;
        justify-content: center;
        display: flex;

    }

    .login_item_two {
        width: 50%;
        left: 154%;
        transform: translateX(-212%);

    }

    .login_item_three {
        width: 50%;
    }

    .login_item_three>div>div {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .login_item_input {
        width: 80%;
        font-size: 0.7rem;
        text-indent:  6px;

    }

    .login_item_title>h1 {
        font-size: 1.3rem;

    }

    .boton_back_Login {
        top: 5%;
        left: 0px;
    }

    .deslizado .login_item_two {
        transform: translateX(-305%);

    }

    .login_item_two_button {
        width: 100px;
        top: 4.8rem;
    }

    .login_item_two_button>button{
        background-color: white;
        border: none;
    }
    
    .login_item_three > div > div ion-icon {
        position: relative;
        left: -2rem;
        top: 0.8rem;
        font-size: 1rem;
    }
    
    .login_item_one > div > div ion-icon {
        position: relative;
        left: -2rem;
        top: 0.8rem;
        font-size: 1.2rem;
    }

}

@media (min-width: 375px) and (max-width: 424px) {
    .login_item_row {
        width: 96%;
    }

    .login_item_one {
        width: 50%;

    }

    .login_item_one>div>div {

        width: 100%;
        justify-content: center;
        display: flex;

    }

    .login_item_two {
        width: 50%;
        left: 154%;
        transform: translateX(-212%);

    }

    .login_item_three {
        width: 50%;
    }

    .login_item_three>div>div {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .login_item_input {
        width: 80%;
        font-size: 0.6rem;
        text-indent:  6px;

    }

    .login_item_title>h1 {
        font-size: 1.3rem;

    }

    .boton_back_Login {
        top: 5%;
        left: 0px;
    }

    .deslizado .login_item_two {
        transform: translateX(-305%);

    }

    .login_item_two_button {
        width: 100px;
        top: 4.5rem;
    }


    .login_item_two_button>button{
        background-color: white;
        border: none;
    }

    
    .login_item_three > div > div ion-icon {
        position: relative;
        left: -2rem;
        top: 0.8rem;
        font-size: 1rem;
    }
    
    .login_item_one > div > div ion-icon {
        position: relative;
        left: -2rem;
        top: 0.8rem;
        font-size: 1.2rem;
    }
}

@media (min-width: 321px) and (max-width: 374px) {
    .login_item_row {
        width: 96%;
    }

    .login_item_one {
        width: 50%;

    }

    .login_item_one>div>div {

        width: 100%;
        justify-content: center;
        display: flex;

    }

    .login_item_two {
        width: 50%;
        left: 154%;
        transform: translateX(-212%);

    }

    .login_item_three {
        width: 50%;
    }

    .login_item_three>div>div {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .login_item_input {
        width: 80%;
        font-size: 0.6rem;
        text-indent:  6px;

    }

    .login_item_title>h1 {
        font-size: 1.3rem;

    }

    .boton_back_Login {
        top: 5%;
        left: 0px;
    }

    .deslizado .login_item_two {
        transform: translateX(-305%);
    }

    .login_item_two_button {
        width: 100px;
        top: 4.7rem;

    }

    .login_item_two_button>button{
        background-color: white;
        border: none;
    }

    .title_login_item_two{
        font-size: 0.9rem;   
    }
    
    .login_item_three > div > div ion-icon {
        position: relative;
        left: -2rem;
        top: 0.8rem;
        font-size: 1rem;
    }
    
    .login_item_one > div > div ion-icon {
        position: relative;
        left: -2rem;
        top: 0.8rem;
        font-size: 1.2rem;
    }

}

@media (max-width: 320px) {
    .login_item_row {
        width: 96%;
    }

    .login_item_one {
        width: 50%;

    }

    .login_item_one>div>div {

        width: 100%;
        justify-content: center;
        display: flex;

    }

    .login_item_two {
        width: 50%;
        left: 154%;
        transform: translateX(-212%);

    }

    .login_item_three {
        width: 50%;
    }

    .login_item_three>div>div {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .login_item_input {
        width: 80%;

    }

    .login_item_title>h1 {
        font-size: 0.9rem;

    }

    .title_login_item_two{
        font-size: 0.9rem;
    }

    .boton_back_Login {
        top: 5%;
        left: 0px;
    }

    .deslizado .login_item_two {
        transform: translateX(-305%);

    }

    .login_item_two_button {
        width: 100px;
        top: 4.5rem;
    }


    .login_item_two_button>button{
        background-color: white;
        border: none;
    
    }

    .login_item_input{
        font-size: 0.5rem;
        text-indent:  6px;
    }

    .login_item_three > div > div ion-icon {
        position: relative;
        left: -2rem;
        top: 0.8rem;
        font-size: 1rem;
    }
    
    .login_item_one > div > div ion-icon {
        position: relative;
        left: -2rem;
        top: 0.8rem;
        font-size: 1.2rem;
    }

}






