.container_CustomCardCoordinator {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;

}

.imgcustom_CustomCardCoordinator {
    height: 90%;
    width: 90%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

}

.item_one_CustomCardCoordinator {
    background-color: #FFFFFF;
    height: 17rem;
    width: 45%;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item_two_CustomCardCoordinator {
    background-color: #FFFFFF;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 17rem;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.item_two_CustomCardCoordinator_title {
    color: rgb(0, 0, 0);
    font-size: 1.3rem;
    font-weight: bold;
    margin: 0;

}

.item_two_CustomCardCoordinator_descrption {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 0.8rem;
    overflow: hidden;
    margin: 0;
    width: 90%;
}

.Days_nights_card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: rgb(172, 168, 168);
    font-size: 1rem;
    font-weight: bold;
    width: 70%;
    text-align: center;
    align-items: center;


}

.ubication_row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: rgb(172, 168, 168);
    font-size: 1rem;
    font-weight: bold;
    width: 100%;

}

.item_two_price_ {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: black;
    font-size: 1.8rem;
    font-weight: bold;
    width: 90%;
}

.price-orange {
    color: orange;
    font-size: 1.2rem;
  }
  
  .price-red {
    color: red;
    font-size: 1.2rem;
  }

.item_two_CustomCardCoordinator_price {
    color: orange;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

.item_two_CustomCardCoordinator_button {
    height: 3rem;
    width: 10rem;
    display: flex;
    border-radius: 20px;
    background-color: #2F2F2F;
    color: white;
    align-items: center;
    justify-content: space-evenly;
    /* margin-bottom: 0.8rem; */
}

.item_two_CustomCardCoordinator_button > ion-icon {
    padding-top: 4px;

}

.item_two_CustomCardCoordinator_price_ {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

.item_two_CustomCardCoordinator_text_ubi {
    margin: 0;
    justify-content: space-evenly;
    display: flex;
    width: 25%;
    align-items: center;

}

.item_two_CustomCardCoordinator_text {
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    width: 40%;
    align-items: center;
}



@media (max-width: 425px) {

.container_CustomCardCoordinator{
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;

}



.item_one_CustomCardCoordinator {
    width: 98%;
    border-radius: 1.5rem 1.5rem 0rem 0rem;
}

.item_two_CustomCardCoordinator{
    width: 98%;
    border-radius: 0rem 0rem 1.5rem 1.5rem;
 
}

.Order_Programming{
    width: 70%;
}

}




