.container_checkBoxCustom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.container_text_checkBoxCustom{
    display: flex;
}


.checkBoxCus_input{
    display: none;
}

.checkBoxCus_spam{
display: inline-block;
position: relative;
height: 1.1rem;
width: 1.1rem;
background-color: white;
border-top-left-radius: 0.2rem;
border-top-right-radius: 0.2rem;
border-bottom-left-radius: 0.2rem;
border-bottom-right-radius: 0.2rem;
transition:  all 0.7s;
border: 0.1rem solid #f0b359;
}



.checkBoxCus_label{
    font-size: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 0.4rem;
}

.checkBoxCus_label .checkBoxCus_input:checked ~ .checkBoxCus_spam{

    background-color: #EC920B;
    border: none;
}

.checkBoxCus_spam::after{
    content: "";
    position: absolute;
    border: solid white;
    border-width: 0 0.2rem 0.2rem 0;
    transform: rotate(45deg);
    width: 0.40rem;
    height: 0.8rem;
    left: 0.37rem;
    bottom: 0.20rem;
    opacity: 0;
    transition: .3s ease;
}




.checkBoxCus_label .checkBoxCus_input:checked ~ .checkBoxCus_spam::after{
    opacity: 1;
}


