.container_suscriptors {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;

}

.suscriptors_item_one {
  background-image: url("../../../imgs/bg-boletin.jpg");
  height: 25rem;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  padding-top: 5rem;
  background-size: cover;
}

.input_email_suscriptors {
  width: 30%;
  height: 3rem;
  border: none;
  outline: none;
  background-color: #ffffff;
  color: white;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.75);
  border-radius: 20px;
  color: #2f2f2f;
  padding-left: 2rem;
  margin-right: 2rem;
}

.input_email_suscriptors::placeholder {
  color: rgba(0, 0, 0, 0.59);
  opacity: 1;
}

.textUserForm_descripcion{
  font-size: small;
  color: white;
  margin-top: 1rem;
  width: 90%;


}

.check_suscriptions_users{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 7rem;
  width: 100%;
  text-align: center;


}

.spam_invicible{
  height: 2rem;
  width: 100%;
  padding-left: 5%;
}

.spam_invicibleText{
  height: 2rem;
  width: 100%;
}


.button_suscriptions_users{
  width: 100%;
  display: flex;
  justify-content: center;
}

.button_email_suscriptors {
  width: 7rem;
  border: none;
  outline: none;
  background-color: #ec920b;
  color: white;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.563);
  border-radius: 20px;
  height: 3rem;
}

.suscriptors_item_two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40rem;
  background-color: #f0f0f0;
}

.contact-us {
  width: 28rem;
  margin-right: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-contactUS {
  width: 40%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2f2f2f;
  border-radius: 20px;
  color: white;
}

.text_cotact_us {
  height: 30%;
  width: 80%;
}

.form-contactUS {
  display: flex;
  flex-direction: column;
}

.phone {
  display: flex;
  width: 95%;
  align-items: center;
}

.label_suscriptors {
  background-color: #2f2f2f;
  margin-top: 3%;
  width: 90%;
}

.items-suscriptors {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  padding-top: 0.9rem;
}


.input_suscriptors {
  width: 90%;
  height: 30px;
  border: none;
  outline: none;
  background-color: #2f2f2f;
  color: white;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.75);
}

.input_suscriptors::-webkit-outer-spin-button,
.input_suscriptors::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.btn_suscriptors {
  width: 50%;
  height: 30px;
  border: none;
  outline: none;
  background-color: #ec920b;
  color: white;
  box-shadow: 0px 1px 0px 0px #ec920b;
  border-radius: 20px;
}

.btn_suscriptors:hover {
  background-color: #e7a137;
  color: white;
  border: none;
}

.check_suscriptors {
  width: 90%;
  border: none;
  outline: none;
  background-color: #2f2f2f;
  color: white;
}

.contact-info {
  width: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group_contact_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
}

.group-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 2rem;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.group-item svg {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.8rem;
  margin-right: 2rem;
  border-radius: 100%;
  background-color: #ec920b;
}

.group-item p {
  margin: 0;
  width: 10rem;
  text-align: left;
  justify-content: center;
}


.form_suscription_validations{
color: rgb(255, 230, 241);
font-size: small;
}

.form_suscriptors_users{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 90%;
}


@media only screen and (max-width:833px) {
  .container_suscriptors {

    background-color: aquamarine;
  }


  .input_email_suscriptors{
    padding-left: 0;
    text-align: center;
   
  }

  .input_email_suscriptors::placeholder {

   font-size: small;
  }

  .suscriptors_item_two {
    display: flex;
    flex-direction: column;
    height: 70rem;
  }

  .contact-us {
    display: flex;
    flex-direction: column;
    height: 60rem;
    margin: 0;
    padding-top: 2rem;

  }

  .contact-us>p {
    text-align: center;
    width: 70%;

  }

  .form-contactUS {
    width: 22rem;
    background-color: #2f2f2f;
  }

  .contact-us{
    margin-top: 2rem;
    width: 100%;
  }

  .contact-info{
    margin-top: 2rem;
    width: 100%;
  }

}

@media only screen and (max-width:374px) {

  .form-contactUS {
    width: 18rem;
}
  
}
