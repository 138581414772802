.container_gallery{
display: flex;
flex-direction: column;
height: auto;
width: 60rem;
overflow: hidden;
justify-content: center;
align-items: center;
width: 40rem;

}

.image-gallery-image{
    border-radius: 1.5rem;
}