.container-lottie {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    z-index: 9999; 
  }



  /* @media only screen and (min-width: 1024px) and (max-width: 1700px) {}

  @media only screen and (min-width: 320px) {} */
