.container_ReservationCard {
    background-color: #F0F0F0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.container_ReservationCard>h1 {
    color: black;
    text-align: left;
    width: 60rem;
    text-align: center;
}

.item_one_ReservationCard {
    height: 35rem;
    width: 70rem; 
    border-radius: 1.5rem;
    display: flex;
    position: sticky;
}

.item_one_ReservationCard > img{
    width: 100%;
    border-radius: 1.5rem;
}

.item_one_description_ReservationCard {
    background-color: #EC920B;
    height: 8rem;
    width: 52rem;
    border-radius: 1.5rem;
    display: flex;
    opacity: 0.8;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    position: absolute;
    bottom: 3rem;
    right: 9rem;
 
}

.item_two_ReservationCard {
    background-color: #FFFFFF;
    margin-top: 3rem;
    width: 70rem;
    border-radius: 1.5rem;
    height: auto;
    display: flex;
    justify-content: center;

}

.itemCardTwo_ReservationCard {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
    width: 61rem;
    padding: 1rem;
}

.itemCardTwo_ReservationCard>h3 {
    font-weight: bold;
    text-align: left;
    width: 90%;
}

.itemCardTwo_ReservationCard>p {
    text-align: justify;
    width: 90%;
    

}

.descriptions_itemCardTwo_ReservationCard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
    width: 61rem;
    padding: 0.8rem;
    background-color: #EC920B;
    height: 6rem;
    border-radius: 2.5rem;
}

.descriptions_itemCardTwo_ReservationCard>div>h4 {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 0.9rem;

}



.item_includes_reservation{
    display: flex;
    flex-direction: row;
    height: 3rem;
    width: 12rem;
    background-color:#EC920B ;
    border-radius: 1.5rem;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 0.8rem;
    
}

.item_includes_reservation > p   {
    margin: 0;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
}

.item_includes_reservation > ion-icon{
    color: white;
    font-size: 1.5rem;

}

.carrosel_reservationCard{

    background-color: #2F2F2F;
    width: 90%;
    padding-bottom: 4rem;
}

.carrosel_reservationCard  .itemsCard .splide__arrow{
    margin: 0px -1rem;
}

.gallery_ReservationCard{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
    width: 90rem;
    padding: 1rem;
    height: 20rem;
    margin-top: 1rem;
}

.gallery_ReservationCard{
    height: auto;
    width:  auto;
}

@media screen and (max-width: 320px){

    .item_one_ReservationCard{
        width: 18rem;
        justify-content: center;
    }

    .item_one_description_ReservationCard{
        width: 90%;
        right: 5%;
    
    }

    .itemCardTwo_ReservationCard {
        width: 20rem;
        padding: 0rem;
    }

    .gallery_ReservationCard{
        width: 20rem;
    }

    .item_two_ReservationCard{
        width: 20rem;
    }

    .descriptions_itemCardTwo_ReservationCard{
        width: 15rem;
        display: flex;
        flex-direction: column;
        height: 22rem;
    }

    .itemCardTwo_ReservationCard>h3{
        text-align: center;
    }
    

 
}

@media screen and (min-width: 320px) and (max-width: 425px) {

    .container_ReservationCard >h1{
        width: 60%;
    }
    .item_one_ReservationCard{
        width: 90%;
        justify-content: center;
    }

    .item_one_description_ReservationCard{
        width: 90%;
        right: 1rem;
    }

    .itemCardTwo_ReservationCard {
        width: 90%;
        padding-bottom: 1rem;
        /* background-color: aqua; */
    }

    .descriptions_itemCardTwo_ReservationCard{
        width: 50%;
        display: flex;
        flex-direction: column;
        height: 22rem;
    }

    .gallery_ReservationCard{
        width: 90%;
    }

    .item_two_ReservationCard{
        width: 90%;
    }

    .descriptions_itemCardTwo_ReservationCard {
        width: 90%;
    }
    .item_includes_reservation{
        margin-top: 1rem;
    }

}


@media screen and (min-width: 426px) and (max-width: 768px) {

    .container_ReservationCard >h1{
        width: 60%;
    }
    .item_one_ReservationCard{
        width: 90%;
        justify-content: center;
    }

    .item_one_description_ReservationCard{
        width: 90%;
        right: 1rem;
    }

    .itemCardTwo_ReservationCard {
        width: 90%;
        padding-bottom: 1rem;
    }

    .descriptions_itemCardTwo_ReservationCard{
        width: 50%;
        display: flex;
        flex-direction: column;
        height: 22rem;
    }

    .gallery_ReservationCard{
        width: 90%;
    }

    .item_two_ReservationCard{
        width: 90%;
    }

    .descriptions_itemCardTwo_ReservationCard {
        width: 90%;
    }
    .item_includes_reservation{
        margin-top: 1rem;
    }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {

    .container_ReservationCard >h1{
        width: 60%;
    }
    .item_one_ReservationCard{
        width: 90%;
        justify-content: center;
    }

    .item_one_description_ReservationCard{
        width: 90%;
        right: 1rem;
    }

    .itemCardTwo_ReservationCard {
        width: 90%;
        padding-bottom: 1rem;
    }

    .descriptions_itemCardTwo_ReservationCard{
        width: 50%;
        display: flex;
        flex-direction: row;
        height: 10rem;
    }

    .gallery_ReservationCard{
        width: 90%;
    }

    .item_two_ReservationCard{
        width: 90%;
    }

    .descriptions_itemCardTwo_ReservationCard {
        width: 90%;
    }
    .item_includes_reservation{
        margin-top: 1rem;
    }

}


@media screen and (min-width: 1025px) and (max-width: 1440px) {

    .container_ReservationCard >h1{
        width: 60%;
    }
    .item_one_ReservationCard{
        width: 90%;
        justify-content: center;
    }

    .item_one_description_ReservationCard{
        width: 80%;
        right: 6rem;
    }
    
    .itemCardTwo_ReservationCard {
        width: 90%;
        padding-bottom: 1rem;
    }
    
    .descriptions_itemCardTwo_ReservationCard{
        width: 50%;
        display: flex;
        flex-direction: row;
        height: 8rem;
    }
    
    .gallery_ReservationCard{
        width: 90%;
    }
    
    .item_two_ReservationCard{
        width: 90%;
    }

    .descriptions_itemCardTwo_ReservationCard {
        width: 90%;
    }
    .item_includes_reservation{
        margin-top: 1rem;
    }

}