.pay_itemCard_ReservationCard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
    width: 61rem;
    padding: 1rem;
    height: 8rem;
    background-color: #F0F0F0;
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
    margin-top: 0.5rem;

}

.pay_itemCard_ReservationCard > div > button{
    background-color: #505050;
    color: white;
    border: none;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    width: 12rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.descriptions_itemCardTwo_ReservationCard_itemsDays{
    display: flex;
    color: white;
    justify-content: space-around;
    width: 14rem;
}
.descriptions_itemCardTwo_ReservationCard_itemsDays > div > h4 {
    font-size: 0.9rem;
    text-align: center;

}

.price_text-calendar{
    display: flex;
    width: 61rem;
    justify-content: space-evenly;
   margin-top: 2rem;
}

.dayAvaliable-user{
    width: 38rem;
   
}

.button_reservation-turism{
    background-color: #505050;
    color: white;
    border: none;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    width: 12rem;
    display: flex;
    justify-content: space;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.calendar-items-day-reservation{
    background-color: #F0F0F0;
    width: 60rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* box-shadow: 0 0 0.5rem 0.1rem #000000; */
}

.calendar-items-day-reservation > h3{
    font-size: 1.5rem;
}

.pay_itemCard_ReservationCard .calendar-items-day-reservation > button{
    font-size: 1.5rem;
    background-color: #EC920B;
    height: 2.9rem;
    width: 12rem;
}

.calendar-text-items{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 50rem;
}

.calendar-text-items > h5{
    font-size: 1.1rem;
    text-align: center;
    margin: 0;
}

.containerCalendar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 61rem;
    
    background-color: #F0F0F0;
}


.items_counter_{
    display: flex;
    flex-direction: row;
    background-color: #F0F0F0;
    width: 61rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    padding-top: 1rem;
    justify-content: center;
}

.NoDiario_items_counter_{
    display: flex;
    flex-direction: row;
    background-color: #F0F0F0;
    width: 61rem;
    border-radius: 1.5rem;
    padding-top: 1rem;
    justify-content: center;
    margin-top: 1rem;
}

.ProgrammingsStyleCounter{
    display: flex;
    flex-direction: row;
    background-color: #F0F0F0;
    width: 61rem;
    border-radius: 1.5rem;
    padding-top: 1rem;
    margin-top: 2rem;
    justify-content: center;

}


.includes_turims_ReservationCard{
    height: auto;
    width: 61rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    background-color: #F0F0F0;
    padding: 0 1rem  2rem 1rem;
    border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.includes_turims_ReservationCard>h3{
    color: black;
    font-weight: bold;
    text-align: left;
    width: 90%;
    padding: 1rem 0 1rem 2rem;

}

.includes_turims_ReservationCard_items{
    display: flex;
    flex-direction: row;
    width: 100%;
}






@media screen and (max-width: 475px){

    .ProgrammingsStyleCounter{
        /* background-color: red ; */
        width: 100%;
        display: flex ;
        flex-direction: column;
    }

    .price_text-calendar{
        width: 20rem;
    }

    .includes_turims_ReservationCard{
        width: 18rem;
        padding: 0;
        
    }

    .pay_itemCard_ReservationCard{
        width: 18rem;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .items_counter_{
        width: 18rem;
        display: flex;
        flex-direction: column;
    }

    .includes_turims_ReservationCard_items{
        display: flex;
        flex-direction: column;
        padding: 0 1rem  2rem 1rem;
        height: 18rem;
        padding-top: 1rem;
        justify-content: space-evenly;
        padding: 0;
        align-items: center;
    }

    .includes_turims_ReservationCard >h3 {
        padding: 0;
        text-align: center;
        padding-top: 1rem;
    }

    .calendar-items-day-reservation{
        width: 16rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .dayAvaliable-user{
        width: 16rem;
    }

    .containerCalendar{
        width: 18rem;
    }


}

@media screen and (min-width: 476px) and (max-width: 768px) {
    .ProgrammingsStyleCounter{
        width: 100%;
        display: flex ;
        flex-direction: column;
    }

     .price_text-calendar{
        width: 90%;
    }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .ProgrammingsStyleCounter{
        width: 100%;
        display: flex ;
        flex-direction: column;
    }

    .price_text-calendar{
        width: 90%;
    }

}

@media screen and (min-width: 321px) and (max-width: 480px) {
    .includes_turims_ReservationCard{
        width: 90%;
        padding: 0;
        
    }

    .pay_itemCard_ReservationCard{
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .dayAvaliable-user{
        width: 90%;
    }

    .containerCalendar{
        width: 90%;
    }

    .items_counter_{
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .includes_turims_ReservationCard_items{
        display: flex;
        flex-direction: column;
        padding: 0 1rem  2rem 1rem;
        height: auto;
        padding-top: 1rem;
        justify-content: space-evenly;
        padding: 0;
        align-items: center;
        padding-bottom: 1rem;
    }

    .price_text-calendar{
        width: 90%;
    }

    .pay_itemCard_ReservationCard {
        height: 20%;
    }

    .calendar-items-day-reservation {
        width: 90%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .NoDiario_items_counter_{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .includes_turims_ReservationCard{
        width: 90%;
        padding: 0;
        
    }

    .pay_itemCard_ReservationCard{
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .dayAvaliable-user{
        width: 90%;
    }

    .containerCalendar{
        width: 90%;
    }

    .items_counter_{
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .includes_turims_ReservationCard_items{
        display: flex;
        flex-direction: column;
        padding: 0 1rem  2rem 1rem;
        height: auto;
        padding-top: 1rem;
        justify-content: space-evenly;
        padding: 0;
        align-items: center;
        padding-bottom: 1rem;
    }

    .price_text-calendar{
        width: 90%;
    }

    .pay_itemCard_ReservationCard {
        height: 20%;
    }

    .calendar-items-day-reservation {
        width: 90%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .NoDiario_items_counter_{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .includes_turims_ReservationCard{
        width: 90%;
        padding: 0;
        
    }

    .pay_itemCard_ReservationCard{
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .dayAvaliable-user{
        width: 90%;
    }

    .containerCalendar{
        width: 90%;
    }

    .items_counter_{
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .includes_turims_ReservationCard_items{
        display: flex;
        flex-direction: row;
        padding: 0 1rem  2rem 1rem;
        height: auto;
        padding-top: 1rem;
        /* justify-content: space-evenly; */
        padding: 0;
        /* align-items: center; */
        padding-bottom: 1rem;
    }

    .price_text-calendar{
        width: 90%;
    }

    .pay_itemCard_ReservationCard {
        height: 20%;
    }

    .calendar-items-day-reservation {
        width: 90%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .NoDiario_items_counter_{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

}

