.container_searcher {
    height: 6rem;
    width: auto;
    background-color: #2F2F2F;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container_search2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

}

.items_row_calendar {
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}


.react-datepicker-ignore-onclickoutside {
    background-color: transparent;
    color: rgb(207, 18, 18);
    border: none;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    width: 6rem;

}

.react-datepicker__input-container>input {
    background-color: transparent;
    color: white;
    border: none;
    text-align: left;
    margin: 0;
    padding: 0;
    font-weight: bold;
    width: 160px;
    height: 21.19px;
    font-size: 0.8rem;
    border: none;
    outline: none;

}



.day_ {
    background-color: transparent;
    color: rgb(255, 255, 255);
    border: none;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    width: 6rem;
}


.items_two_calendar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.3rem;


}

.btn_searcher {
    height: 2rem;
    width: 12rem;
    background-color: #EC920B;
    border: none;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.btn_searcher:hover {
    color: #e3f1e5;
}



.btn_clean {
    height: 1rem;
    border: none;
    cursor: pointer;
    border-radius: 100%;
}

.div_circle {
    height: 2rem;
    /* width: 2.5rem; */
    background-color: #EC920B;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 1%;

}

.container_logo_trash {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
}

.input_search_turims {
    /* width: 10rem; */
    font-size: 0.8rem;
    font-weight: bold;
    background-color: transparent;
    color: white;
    margin-left: 0.3rem;


}

.input_search_turims::-webkit-outer-spin-button,
.input_search_turims::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.items_calendar>ion-icon {
    color: rgb(230, 157, 21);
}



.items_calendar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: rgb(173, 173, 173) solid 1px;
}

.items_calendar>input {
    outline: none;
    border: none;
}


@media only screen and (min-width: 1024px) and (max-width: 2600px) {

    .container_searcher {
        width: 60rem;
    }

    .input_search_turims {
        width: 10rem;
        border-bottom: #EC920B;
    }

    .calendar {
        width: 15rem;
    }

    .day_ {
        width: 5rem;
    }

    .items_row_calendar {
        justify-content: space-evenly;
        width: 60rem;
        display: flex;
    }

    .items_calendar {
        width: 12rem;
    }


    .items_two_calendar {
        width: 45rem;
    }

    .btn_searcher {
        font-size: 0.7rem;
    }

    .div_circle {


        margin-left: 1rem;

    }


}

@media only screen and (min-width:721px) and (max-width: 1023px) {

    .container_searcher {
        width: 45rem;
    }

    .input_search_turims {
        width: 5rem;
        border-bottom: #EC920B;
    }

    .calendar {
        width: 10rem;
    }

    .day_ {
        width: 3rem;
    }

    .items_row_calendar {
        justify-content: space-evenly;
        width: 45rem;
        display: flex;
    }

    .items_calendar {
        width: 8rem;
    }

    .items_two_calendar {
        width: 30rem;
    }

    .items_calendar .calendar .day_ {
        font-size: 0.5rem;
        color: white;
    }

    .btn_searcher {
        font-size: 0.8rem;
    }
}


@media only screen and (min-width:320px) and (max-width: 720px) {

    .container_searcher {
        width: 15rem;
        height: 15rem;
        justify-content: space-evenly;
        margin: 1.5rem 0 2rem 0;

    }

    .input_search_turims {
        width: 3rem;
        border-bottom: #EC920B;
        font-size: 0.6rem;

    }

    .calendar {
        width: 6rem;
    }

    .items_row_calendar {

        justify-content: space-evenly;
        width: 10rem;
        display: flex;
        flex-direction: column;
        height: 10rem;

    }

    .items_calendar {
        width: 12rem;
        justify-content: start;
    }

    .items_two_calendar {
        width: 12rem;
    }

    .seconds_items_TravelCoordinator {
        width: 50rem;
    }

    .items_calendar .calendar .day_ {
        font-size: 0.6rem;
        color: white;
    }

    .items_calendar .calendar>ion-icon {
        display: flex;
        align-self: center;
        font-size: 1rem;
        border: none;
    }

    .items_calendar>ion-icon {
        font-size: 1rem;

    }

    .btn_searcher {
        width: 15rem;
        font-size: 0.6rem;
        height: 2rem;
    }

    .div_circle {
        height: 2rem;
        width: 2rem;
        margin-left: 0.5rem;

    }


}