.container_FormCustom {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 1rem;
    padding: 2rem; */
    /* background-color: #2F2F2F; */
    border-radius: 1.5rem;
    width: auto;
}

.container_FormCustom>p {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    color: black;
    font-size: 1.5rem;
    margin: 1rem;
    background-color: #EC920B;
    width: 54rem;
    text-align: center;
    height: 2.5rem;
}

.container_FormCustom__form__input {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 55rem;
    margin-bottom: 1rem;
    /* background-color: #EC920B; */
}

.items_formcustom_colum {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    width: 25rem;
}


.container_FormCustom>form {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

   

}

.container_FormCustom__form__input .items_formcustom_colum > input {
    height: 2.5rem;
    padding: 0.5rem;
    border: none;
    outline: none;
    background-color: white;
    color: black;
    /* border: 1px solid #EC920B; */
    border-radius: 0.8rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.container_FormCustom__form__input .items_formcustom_colum > select {
    height: 2.5rem;
    padding: 0.5rem;
    border: none;
    outline: none;
    background-color: white;
    color: black;
    /* border: 1px solid #EC920B; */
    border-radius: 0.8rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.container_dinamic_Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    width: 100rem;
}

.nav-link {
    color: black;
}

.nav-link:hover {
    color: #EC920B;
}

.card-header-tabs .nav-link.active {
    background-color: #EC920B;
    color: white;
}

.card-body {
    border-radius: 1.5rem;
    width: 40rem;

    border: none;
}

.card-title {
    color: white;
}


.card-header-tabs {
    border-radius: 1.5rem;
    border: none;
}

.card-header {
    background-color: white;
    border-radius: 1.5rem;
    border: none;
}



.accordion_container {
    width: 100%;
    /* background-color: rgb(119, 0, 255); */

}


.container_FormCustom .container_FormCustom__form__input label {
    padding-left: 1rem;
    color: black;
    font-weight: 600;
    padding-bottom: 0.5rem;
}

/* ----------------ACORDEON---------------------------------- */

.accordion-body {
    background: rgba(214, 214, 214, 0.9);

    /* width: 58rem; */
    border-radius: 0 0 1.5rem 1.5rem;
    padding: 1rem 2rem;
    color: white;

}

.accordion-header:not(.accordion-button:not(.collapsed)) {
    width: 60rem;
    border-radius: 1.7rem;
}

.accordion-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: none;
    background-color: transparent;
}

.accordion-header {
    background-color: #DADADA;
    color: white;
    border-radius: 1.5rem;
    cursor: pointer;
    margin-bottom: 1rem;
    outline: none;
}

.accordion-button {
    padding: 1rem;
    color: white;
    border: transparent;
    border-radius: 1.5rem;
    width: 52rem;
    text-align: left;
    font-size: 1.3rem;
    outline: none !important;
}

.accordion {
    --bs-accordion-active-bg: #EC920B;
    --bs-accordion-active-color: white;
    padding: 0;
    margin: 0;
    border: none;
    --bs-accordion-btn-focus-box-shadow: none !important;
    --bs-accordion-border-width: none !important;
}

.accordion-item .accordion-button {
    border-radius: 10rem !important;

}


.accordion-button .collapsed {
    border-radius: 1rem !important; 
    transition: none !important;
    outline: none !important;
}

.accordion-button:not(.collapsed) {
    transition: none !important;
    border-bottom: none;
    outline: none;
    border: none;
}

.accordion-header > button {
  
    background-color: #DADADA;
    color: black;
    font-size: 1.3rem;
    font-weight: bold;
    width: 100%;
    transition: none;
    outline: none;

}

.accordion-button:focus{
    color: white;
    outline: none;
    border: none;
    transition: none;
}


@media (max-width: 425px) {
    .container_FormCustom {
        width: 90%;
    }

    .container_FormCustom>p {
        width: 100%;
    }

    .container_FormCustom__form__input {
        flex-direction: column;
        width: 100%;
    }

    .items_formcustom_colum {
        width: 100%;
    }

    .card-body {
        width: 100%;
    }

    .accordion-header:not(.accordion-button:not(.collapsed)) {
        width: 100%;
    }

    .accordion-button {
        width: 100%;
    }
}

@media screen and (min-width: 426px) and ( max-width: 768px) {
    .accordion-body {
        width: 100%;
        background-color: aqua;
    }
    
    .container_FormCustom>p {
        width: 100%;
    }
    
    .container_FormCustom__form__input {
        flex-direction: column;
        width: 100%;
    }
    
    .items_formcustom_colum {
        width: 100%;
    }
    
    .card-body {
        width: 100%;
    }
    
    .accordion-header:not(.accordion-button:not(.collapsed)) {
        width: 100%;
    }
    
    .accordion-button {
        width: 100%;
    }
}






