.container_itemCardCustom {
  height: 22rem;
  background-color: rgb(255, 255, 255);
  width: 250px !important;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: center;
}

.cardCustom__img {
  height: 170px;
  width: 90%;
}

.cardCustom__img img {
  width: 100%;
  height: 160px;

}

.imgcustom {
  border-radius: 15px;
  z-index: 2;
  margin-bottom: 15px;

}

.info-items-custom {
  width: 100%;
  height: 34%;

}

.itemsday {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}


.CenterP {
  text-align: center;
  align-items: center;
  font-size: 14px;
  margin: 0;
}

.itemsNightTurims {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  font-size: 14px;
}

.cardCustom__day {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.btnCardCustom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 40%;
  background-color: #EC920B;
  border-radius: 15px;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  border: none;
  margin-top: 0.5rem;
}

.cardCustom__name {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

.cardCustom__precio {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.2rem;
}

.discounted {
  color: black;
  margin-top: 0.5rem;
}

.normalPrice.discounted {
  color: red;
  text-decoration: line-through;
  margin-top: 0.5rem;

}

.discounted>p {

  margin-top: 0.5rem;

}

.center_text_and_Icon {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 8rem;

}

.center_text_and_Icon p {
  margin: 0;
  width: 100%;
}

.center_text_and_Icon svg {
  margin-right: 0.5rem;
  justify-content: center;
}



@media only screen and (max-width: 768px) {

  .container_itemCardCustom {
    height: 100%;
  }


}