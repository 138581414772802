
.navbar {
    background-color: #0f0f0f !important;
    position: relative;
}

.navbar-toggler {
    background-color: #2f2f2f !important;
}

.navbar-collapse {
    justify-content: flex-end;
}

.nav-link{
    color: #ffff !important;
    align-items: center;
    text-align: center;
}

.icon_login {
    height: 100%;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.dropdown-menu.show {
    top: 170% !important;
    left: auto !important;
    right: 0 !important;
  }

  .user-names-subMenu{
    color: white;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    text-align: center;
  }

/* @media only screen and (min-width: 768px) and (max-width: 1024px) {} */

/* @media only screen and (min-width: 1025px) and (max-width: 1280px) {}  */
@media only screen and (min-width: 992px)  {
    .navbar{
        width: 100%;
        padding-inline: 2rem ;
        padding-block: 1rem;
    }

    
}


@media only screen and (min-width: 1561px) {
    .navbar{
        width: 100%;
    }

}
