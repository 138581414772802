.containerAboutUs {
  background-color: #ffffff;
  display: flex;
  height: 50rem;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.aboutUs_item_one {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 0.05%;
  /* height: 100vh; */
  background-color: #ffffff;
}

.aboutUs_item_two {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  /* height: 90vh; */
  background-color: #ffffff;
  /* padding-top: 6%; */
}

.aboutUs-image {
  width: auto;
  height: 35rem;
  display: flex;
  position: relative;
  right: 26%;
  float: right;
}

.aboutUs_item_one h1 {
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 2rem;
  font-weight: 500;
}

.aboutUs-description {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  background-color: #ffffff;
  text-align: justify;
}

.aboutUs-description span {
  font-size: 1.5rem;
  color: #EC920B;
  margin-bottom: 2rem;
  font-weight: 400;
  cursor: pointer;
}

.aboutUs-description span:hover {
  color: #c17c13;
}

@media only screen and (max-width: 425px) {
  .containerAboutUs {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .aboutUs_item_one {
    width: 100%;
  }

  .aboutUs_item_two {
    margin-top: 1%;
    width: 100%;
    height: 10rem;
  }

  .aboutUs-image {
    width: 100%;
    height: auto;
    position: relative;
    right: 10%;
  }

  .aboutUs-description {
    width: 80%;
    display: flex;
  }

  .aboutUs-description span {
    font-style: oblique;
    text-align: center;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {

  .containerAboutUs {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .aboutUs_item_one {
    width: 100%;
  }

  .aboutUs_item_two {
    margin-top: 15%;
    width: 100%;
    height: 10rem;
  }

  .aboutUs-image {
    width: 100%;
    height: auto;
    position: relative;
    right: 10%;
  }

  .aboutUs-description {
    width: 80%;
    display: flex;
  }

  .aboutUs-description span {
    font-style: oblique;
    text-align: center;
  }

}

@media only screen and (min-width: 769px) {
  .containerAboutUs {
    max-width: 120rem;
    margin-inline: auto;
  }

  .aboutUs-image {
    width: 130%;
    max-width: 60rem;
    height: 120%;
    max-height: 40rem;
    position: relative;
    right: 10%;
  }

  .aboutUs-description span {
    margin-top: 1rem;
    font-style: oblique;
    text-align: center;
  }


}