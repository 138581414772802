.containerRecommended {
    width: 100%;
    height: auto;
    background-color: #2F2F2F;
    padding: 3rem 0 4rem 0;

}

.containerRecommended .splide__track {
    width: 85%;
    ;
    margin: 0 auto 5rem auto;
}

.splide_recommended .splide__slide {
    display: flex;
    justify-content: center;
}

.splide__arrow>svg {
    fill: #fff;
}

.splide__arrow {
    background-color: #ec920b;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.titlefilter {
    color: #ec920b;
    font-size: 2.7rem;
    font-weight: 400;
    text-align: start;
    margin: 0 0 2rem 11rem;
}


.carrusel_no_data {
    width: 100%;
    color: #fff;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
}


@media only screen and (max-width: 768px) {
    .containerRecommended {
        padding: 2rem 0 2rem 0;
    }

    .titlefilter {
        margin: 0 1rem 1rem 1rem;
        font-size: 1.7rem;
        text-align: center;
    }

    .splide__arrow {
        margin: 0 .5rem;
    }

    .splide__arrow--prev {
        width: 2rem;
        height: 2rem;
        left: -.2rem;
    }

    .splide__arrow--next {
        width: 2rem;
        height: 2rem;
        right: -.3rem;
    }
}