.containerFormUser {
    height: auto;
    width: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: aquamarine; */
}

.adduser-form-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 38rem;
    justify-content: center;
    /* margin-top: 1rem; */
}

.addUser-title {
    display: flex;
    flex-direction: row;
    width: 20rem;
    height: 5rem;
}

.addPerson-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 16rem;
}

.containerFormUser .adduser-form-items .addPerson-form>div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 60%;
    font-size: 1.5rem;

}


.addPerson-form>h3 {
    font-size: 1.3rem;
    /* margin-bottom: 1rem; */
}



.addPerson-form>div>h5 {
    font-size: 1.3rem;
    width: 6rem;
    text-align: center;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

}

.addPerson-form>div{
    /* background-color: aqua; */
    border-radius: 0.5rem;
}

.addPerson-form > h3{
    padding-top: 1rem;
}

.addPerson-form>div>button>ion-icon {
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.addUser-title > h2 {
    font-size: 1.3rem;
    margin: 0;
    /* background-color: azure; */
    width: 13rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.containerFormUser .adduser-form-items .addUser-title > ion-icon {
    font-size: 1.7rem;
    margin-right: 1rem;
    color: #EC920B;
    height: 2rem;
}

.disabledButton {
    /* background-color:#294faf; */
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    height: 2rem;
    width: 2.3rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 3rem;

}

.button_PassengerQuantity {
    border: none;
    background-color: transparent;
    cursor: pointer;

}

.containerFormUser .adduser-form-items .addPerson-form>div{
    height: 2rem;
}

.counter-off-persons{
    background-color: #505050;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

}

.counter-on-persons{
    background-color: #EC920B;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    
}




.price_in_counter-persons{
    width:18rem;
    display: flex;
    justify-content: center;
}


.dayAvaliable-user{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20rem;
    background-color: #F0F0F0;
}


@media screen and (max-width: 320px){
    .containerFormUser{
        width: 18rem;
    }

    .adduser-form-items{
        width: 20rem;
        display: flex;
        flex-direction: column;
    }

    .addUser-title{
        height: 2rem;
        width: 16rem;
    }
}

@media screen and (min-width: 321px) and (max-width: 480px) {

   .containerFormUser{
        width: 50%;
    }

    .adduser-form-items{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .containerFormUser{
        width: 100%;
    }

    .addUser-title{
        width: 100%;
    }

    .price_in_counter-persons{
        width: 100%;
    }
}

@media screen and (min-width: 481px) and (max-width: 868px) {
    .containerFormUser{
        width: 50%;
    }

    .adduser-form-items{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .containerFormUser{
        width: 100%;
    }

    .addUser-title{
        width: 100%;
    }

    .price_in_counter-persons{
        width: 100%;
    }
}

@media screen and (min-width: 869px) and (max-width: 1023px) {
    .containerFormUser{
        width: 50%;
    }

    .adduser-form-items{
        width: 90%;
        display: flex;
        flex-direction: row;
    }

    .containerFormUser{
        width: 100%;
    }

    .addUser-title{
        width: 100%;
    }

    .price_in_counter-persons{
        width: 100%;
    }
}