.container_social_media {
  position: absolute;
  top: 10rem;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
  color: white;
  height: 10rem;
  width: 5rem;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  transition: 0.5s ease;
  animation: 1s ease-in-out 0s 1 slideInFromRight;
}

.container_social_media>div>a>ion-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  display: flex;
}

.container_fb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  background-color: #3b5998;
  border-top-right-radius: 1.5rem;
}

.container_gmail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  background-color: #747473;
}

.container_ig {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  background: linear-gradient(135deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d, #f56040, #f77737, #fcaf45, #ffdc80);
  border-bottom-right-radius: 1.5rem;
}

.container_wp {
  position: absolute;
  bottom: -4.5rem;
  right: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  background-color: #25d366;
  border-radius: 100%;
  color: white;
  z-index: 1001;
}

.container_wp>ion-icon {
  cursor: pointer;
  font-size: 2rem;
}

.overlay {
  position: absolute;
  left: 120%;
  background-color: #1c1c1c;
  color: white;
  padding: 0.5rem 0.8rem;
  opacity: 0;
  font-family: bold;
}

.container_fb:hover .overlay,
.container_gmail:hover .overlay,
.container_ig:hover .overlay {
  opacity: 1;
}

.container_fb:hover .overlay::after,
.container_gmail:hover .overlay::after,
.container_ig:hover .overlay::after {
  position: absolute;
  content: '';
  border-left: 7px solid transparent;
  border-right: 6px solid #1c1c1c;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  right: 100%;
  top: 35%;
}

.container_wp:hover {
  color: black;
}



@media only screen and (max-width: 768px) {

  .container_social_media {

    width: 3rem;

  }

  .container_wp {
    right: 2.3rem;
  }

}


@media only screen and (max-width: 425px) {
  .container_wp {
    right: 1.3rem;
    bottom: -4.5rem;
    height: 3rem;
    width: 3rem;
  }

}

@media only screen and (max-width: 320px) {
  .container_wp {
    right: 0.5rem;
    bottom: -5rem;
    height: 3rem;
    width: 3rem;
  }

}