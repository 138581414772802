.container_MethodPayment{
    background-color: #DADADA;
    width: auto;
    height: auto;
    border: 4px solid #000000;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
}

.MethodPayment_item{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: 50rem;
}

.MethodPayment_item > img {
    padding-left: 3rem;
}

.text_img{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: aqua; */
    width: 28rem;
}

.input_MethodPayment{
    height: 2rem;
    background-color: #2F2F2F;
    color: white;
    padding-left: 0.9rem ;
    appearance: none; 
   border-radius: 0.5rem;
}


.input_MethodPayment > option{
    background-color: #2F2F2F;
    color: white;
    cursor: pointer;
    padding-left: 5rem;
    
}

.methodPaymentTotal{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: 50rem;
}

.methodPaymentTotal_items{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: 50rem;
}

.button_reservationpay{
    height: 3rem;
    width: 10rem;
    display: flex;
    border-radius: 20px;
    background-color: #2F2F2F;
    color: white;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.8rem;
    border: none;
    margin-top: 1rem;
}

.button_reservationpay:hover{
    background-color: #EC920B;
    cursor: pointer;
}

.container_button_payment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.costs_pay >h5{
     text-align: end;
}

.container_button_payment{
    padding: 1rem;
}

.input_MethodPayment::-ms-expand {
    display: none; /* Remove default dropdown arrow in IE */
  }


  @media screen and (max-width: 425px){
 

    .container_MethodPayment{
      width: 100%;
    }

    .MethodPayment_item{
      width: 100%;
    }

    .text_img{
      width: 100%;
    }

    .input_MethodPayment{
      width: 100%;
    }

    .methodPaymentTotal{
      width: 100%;
    }
  }

  @media screen and (min-width: 426px) and (max-width: 768px){
 

    .container_MethodPayment{
      width: 100%;
    }

    .MethodPayment_item{
      width: 100%;
    }

    .text_img{
      width: 100%;
    }

    .input_MethodPayment{
      width: 100%;
    }

    .methodPaymentTotal{
      width: 100%;
    }
  }


   