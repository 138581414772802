.container_TravelCoordinator {
  width: 100%;
  align-items: center;
  background-color: #EFEFEF;
  height: auto;
}

.item_one_TravelCoordinator {

  background-image: url('../../imgs/canon-chicamocha-colombia.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  height: 38rem;
}


.title_TravelCoordinator {
  font-size: 2rem;
  font-weight: bold;
}

.description_TravelCoordinator {
  font-size: 1rem;
}


.items_TravelCoordinator {
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60rem;
}

.item_two_TravelCoordinator {

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EFEFEF;

}

.seconds_items_Travel_Coordinator {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 5rem;
  width: 64rem;
  background-color: #EFEFEF;
  margin-top: 2rem;

}




.select_TravelCoordinator {
  height: 3.5rem;
  width: 12rem;
  background-color: #EFEFEF;
  border: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 1.4rem;
  font-weight: bold;
  color: black;
  text-align: center;
  border: none;

}

.select_TravelCoordinator:hover {

  color: black;
  cursor: pointer;
  border: none;

}

.select_TravelCoordinator:focus {
  outline: none;
  border: none;
}

.select_TravelCoordinator > option {
  font-size: 1rem;
  font-weight: bold;
  color: black;
  text-align: center;
  border: none;
}


.Order_Programming {
  display: flex;
  align-items: center;
}

.Order_Programming > h1{
  font-size : 1.5rem;
 
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 7rem;
  width: 50rem;
  background-color: #EFEFEF;
  width: 50%;
}

.item_two_Turims {
  padding-top: 50px;
  background-color: #0ccde6;
  height: 150vh;
}


.pagination>button {
  height: 2.8rem;
  width: 6rem;
  background-color: #EC920B;
  border: none;
  font-weight: bold;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 2px 2px rgba(32, 32, 32, 0.6);

}

.pagination>button:hover {
  color: #e3f1e5;
}

.pagination>span {
  font-weight: bold;

}

.activePage {
  font-weight: bold;
  color: white;
  background-color: #ec920b;
  width: 2rem;
  height: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
  border-radius: 50%;
}

.second_Title_Travel_Coordinator{
  font-size: 1.5rem;
  height: 5rem;
  align-items: center;
  display: flex;
  justify-content: center;
}


@media (min-width: 768px) {
  
  .seconds_items_Travel_Coordinator{
    width: 100%;
  }

  .items_TravelCoordinator {
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40rem;
  }
}


@media (min-width: 425px) and (max-width: 767px) {
  .seconds_items_Travel_Coordinator{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
  }

  .items_TravelCoordinator{
    width: 100%;
  
  }
}

@media (min-width: 321px) and (max-width: 424px) {

  .seconds_items_Travel_Coordinator{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 6rem;
  }
  .Order_Programming > h1{
    font-size: 1.3rem;
  
  }

  .items_TravelCoordinator{
    width: 100%;
  }
  
}



@media (max-width: 425px) {

  .container_TravelCoordinator{
    height: auto;
    background-color: blue; 
  }

  .item_one_TravelCoordinator{
    height: auto;
  }


  .item_two_TravelCoordinator{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EFEFEF;
  }

  .items_TravelCoordinator{
    width:80%;
    height: 15rem;
  
  }

  .seconds_items_Travel_Coordinator{
    width: 100%;
    font-display: flex;
    flex-direction: column;
    height: 10rem;
    padding: 0;
    margin: 0;
  }

 

  .Order_Programming > h1{
    font-size: 1.3rem;
  }

  .pagination{
    width: 100%;
    height: 5rem;
  }
  

  .pagination > button{
    width: 5rem;
    height: 2.5rem;
    font-size: 0.8rem;
  }

}

