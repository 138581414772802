.containerYouTravel {
    display: flex;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tittle-travel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tittle-travel h1 {
    font-weight: 500;
    color: #000000;
    margin: 5rem 0 2rem 0;

}

.tittle-travel p {
    font-size: 1rem;
    color: #000000;
    margin: 0 0 2rem 0;
}

.image-travel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
    background-color: #FFFFFF;

}

.bottons-travel {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 6rem;
    background-color: #FFFFFF;
}

.bottons-W {
    width: 58rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 3rem;
    cursor: pointer;
}

.bottons-W a {
    display: inline-block;
    cursor: pointer;     
}

.bottons-W img {
    width: 100%;           
    height: auto;       
    display: block;       
}

.youTravel-image {
    height: 100%;
}

.image-travel {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-bottom: 2%;

}

@media only screen and (max-width: 425px) {
    .tittle-travel {
        width: 80%;
        margin-inline: auto;
        text-align: center;
    }

    .tittle-travel h1 {
        margin: 2rem 0 1rem 0;
    }

    .image-travel-container {
        height: auto;
    }

    .youTravel-image {
        width: 100%;
    }

    .bottons-travel {
        margin-top: 4rem;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bottons-travel>.bottons-W {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
    }

    .bottons-travel>.bottons-W>a {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .tittle-travel {
        width: 80%;
        margin-inline: auto;
        text-align: center;
    }

    .tittle-travel h1 {
        margin: 2rem 0 1rem 0;
    }

    .image-travel-container {
        height: auto;
    }

    .youTravel-image {
        width: 100%;
    }

    .bottons-travel {
        margin-top: 4rem;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bottons-travel>.bottons-W {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
    }

    .bottons-travel>.bottons-W>a {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 1024px) {
    .tittle-travel {
        width: 80%;
        margin-inline: auto;
        text-align: center;
    }

    .tittle-travel h1 {
        margin: 2rem 0 1rem 0;
    }

    .image-travel-container {
        height: auto;
    }

    .youTravel-image {
        width: 100%;
    }

    .bottons-travel {
        margin-top: 3rem;
        height: auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .bottons-travel>.bottons-W>a {
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: 1025px) {

    .image-travel{
        width: 80%;
        /* background-color: aquamarine; */
    }

    .image-travel-container{
        width: 100%;
    }
    .youTravel-image{
        width: 100%;
    }

}